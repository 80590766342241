import React, { useState, useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import StickyHeader from '../components/stickyHeader';
import { toShortFormat } from "../utils/dateFormatter";
import iconUpstream from "../images/icon_upstream.png";

const News = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    handleFetchNews();
  }, []);

  const handleFetchNews = () => {
    fetch("/api/get/news")
      .then(res => res.json())
      .then(data => {
        const { rtCode, rtData: { news } } = data;
        if (rtCode === "S00") {
          news.forEach((item) => {
            const postDate = toShortFormat(new Date(item.originPostDate));
            setNewsList(prev => ([
              ...prev,
              {
                newsId: item.id,
                newsTitle: item.newsTitle,
                newsContent: item.newsContent,
                postDate,
                targetUrl: item.targetUrl,
                featureImage: item.featureImage,
                videoEmbedUrl: item.videoEmbedUrl,
                videoId: item.videoId
              }
            ]))
          });
        }
      })
      .catch(err => console.error(err.message));
  }

  return (
    <Layout>
      <SEO title="News" />
      <Header staticHeader />
      <StickyHeader bgBlack />
      <section className="content">
        <div className="content__section container news__content">
          <h2 className="heading__primary">Latest News</h2>
          <ul className="news__list">
            {newsList.map((news, idx) => (
              idx < 5 ? (news.videoEmbedUrl !== "" ? (
                <li className="news__item" key={news.newsId}>
                  <div className="news__image">
                    <div className="video__container">
                      <iframe width="560" height="315" src={`https://www.youtube.com/embed/${news.videoId}`} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
                  <div className="news__text">
                    <a href={news.videoEmbedUrl} target="_blank" className="news__link">
                      <h3 className="news__title"><span className="news__title--text">{news.newsTitle}</span><span className="news__title-icon"><img src={iconUpstream} alt="upstream icon" /></span></h3>
                    </a>
                    <p className="news__metadata">{news.postDate}</p>
                    <div className="news__excerpt" dangerouslySetInnerHTML={{ __html: news.newsContent }}></div>
                  </div>
                </li>
              ) : (
                  <li className="news__item" key={news.newsId}>
                    <a href={news.targetUrl} target="_blank" className="news__link">
                      <div className="news__image">
                        <img src={news.featureImage} alt={news.newsTitle} />
                      </div>
                    </a>
                    <div className="news__text">
                      <a href={news.targetUrl} target="_blank" className="news__link">
                        <h3 className="news__title"><span className="news__title--text">{news.newsTitle}</span><span className="news__title-icon"><img src={iconUpstream} alt="upstream icon" /></span></h3>
                      </a>
                      <p className="news__metadata">{news.postDate}</p>
                      <div className="news__excerpt" dangerouslySetInnerHTML={{ __html: news.newsContent }}></div>
                    </div>
                  </li>
                )
              ) : (
                  <li className="news__item--sub" key={news.newsId}>
                    <a href={news.target_url} target="_blank" className="news__link--sub"><h3 className="news__title--sub"><span className="news__title--text">{news.newsTitle}</span><span className="news__title-icon--sub"><img src={iconUpstream} alt="upstream icon" /></span></h3></a>
                    <p className="news__metadata--sub">{news.postDate}</p>
                  </li>
                )))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default News
